import { User as FirebaseUser } from '@firebase/auth';
import {
  FirewardGetOutput,
  Installation,
  LicenseKey,
  StripePayment,
  StripeSubscription,
} from 'cogsFirestore';
import { Dictionary, fromPairs } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  useIsLoggedInAsAdminUser,
  useLoggedInUser,
  useUserIdWithQueryOverride,
} from '../utils/firebase/auth';
import {
  useCogsInstallations,
  useCogsLicenseKeys,
  useAllStripeLicenseProducts,
  useStripeSubscriptions,
  useMultipleProductPrices,
  useTaxRates,
  useStripePayments,
} from '../utils/firebase/firestore';
import { getUser } from '../utils/firebase/functions';
import { useInstallationStatuses } from '../utils/firebase/realtimeDb';

export interface UserData {
  userId?: string;
  user?: FirebaseUser | null;
  isLoggedInAsAdminUser?: boolean;
  installations?: ReturnType<typeof useCogsInstallations>;
  installationsById?: Dictionary<Installation<FirewardGetOutput>>;
  installationStatuses?: ReturnType<typeof useInstallationStatuses>;
  licenseKeys?: ReturnType<typeof useCogsLicenseKeys>;
  licenseKeysById?: Dictionary<LicenseKey<FirewardGetOutput>>;
  allProducts?: ReturnType<typeof useAllStripeLicenseProducts>;
  allProductPrices?: ReturnType<typeof useMultipleProductPrices>;
  activeProducts?: ReturnType<typeof useAllStripeLicenseProducts>;
  subscriptions?: ReturnType<typeof useStripeSubscriptions>;
  subscriptionsById?: Dictionary<StripeSubscription<FirewardGetOutput>>;
  activeSubscriptions?: ReturnType<typeof useStripeSubscriptions>;
  activeSubscriptionsById?: Dictionary<StripeSubscription<FirewardGetOutput>>;
  taxRates?: ReturnType<typeof useTaxRates>;
  payments?: ReturnType<typeof useStripePayments>;
  paymentsById?: Dictionary<StripePayment<FirewardGetOutput>>;
}

const UserDataContext = React.createContext<UserData>({});

export function useUserData() {
  return useContext(UserDataContext);
}

export default function UserDataProvider({ children }: { children?: React.ReactNode }) {
  const userId = useUserIdWithQueryOverride();
  const isLoggedInAsAdminUser = useIsLoggedInAsAdminUser();

  const loggedInUser = useLoggedInUser();
  const [user, setUser] = useState<FirebaseUser>();
  useEffect(() => {
    if (!userId || !loggedInUser) {
      return;
    }
    if (userId !== loggedInUser.uid) {
      isLoggedInAsAdminUser && getUser(userId).then(setUser);
    } else {
      setUser(loggedInUser);
    }
  }, [isLoggedInAsAdminUser, setUser, userId, loggedInUser]);

  const installations = useCogsInstallations(userId);
  const installationsById = useMemo(() => fromPairs(installations), [installations]);

  const installationStatuses = useInstallationStatuses(userId);

  const licenseKeys = useCogsLicenseKeys(userId);
  const licenseKeysById = useMemo(() => fromPairs(licenseKeys), [licenseKeys]);

  const allProducts = useAllStripeLicenseProducts();

  const allProductIds = useMemo(() => allProducts?.map(([id]) => id), [allProducts]);
  const allProductPrices = useMultipleProductPrices(allProductIds);

  const activeProducts = useMemo(
    () =>
      allProducts?.filter(
        ([productId, product]) =>
          product.active &&
          // Has active price
          allProductPrices?.[productId]?.some((price) => price[1].active)
      ),
    [allProductPrices, allProducts]
  );

  const subscriptions = useStripeSubscriptions(userId);
  const subscriptionsById = useMemo(() => fromPairs(subscriptions), [subscriptions]);

  const activeSubscriptions = useMemo(
    () =>
      subscriptions?.filter(
        ([, subscription]) =>
          subscription.status === 'active' &&
          !subscription.cancel_at &&
          !subscription.cancel_at_period_end
      ),
    [subscriptions]
  );
  const activeSubscriptionsById = useMemo(
    () => fromPairs(activeSubscriptions),
    [activeSubscriptions]
  );

  const taxRates = useTaxRates();

  const payments = useStripePayments(userId);
  const paymentsById = useMemo(() => fromPairs(payments), [payments]);

  const context: UserData = {
    userId,
    user,
    isLoggedInAsAdminUser,
    installations,
    installationsById,
    installationStatuses,
    licenseKeys,
    licenseKeysById,
    allProducts,
    allProductPrices,
    activeProducts,
    subscriptions,
    subscriptionsById,
    activeSubscriptions,
    activeSubscriptionsById,
    taxRates,
    payments,
    paymentsById,
  };

  return <UserDataContext.Provider value={context}>{children}</UserDataContext.Provider>;
}
