import { FirewardGetOutput, Installation, StripeSubscription } from 'cogsFirestore';
import { User as FirebaseUser } from 'firebase/auth';
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';
import { useCallback, useEffect, useState } from 'react';
import type Stripe from 'stripe';
import { useLoggedInUser } from './auth';
import { EMULATOR_HOST } from './environment';
import firebaseApp, { EMULATOR_PORTS } from './firebaseApp';

export const functions = {
  defaultRegion: getFunctions(firebaseApp),
  europeWest1: getFunctions(firebaseApp, 'europe-west1'),
};

if (EMULATOR_HOST) {
  for (const region of Object.values(functions)) {
    connectFunctionsEmulator(region, EMULATOR_HOST, EMULATOR_PORTS.functions);
  }
}

export async function getUser(userId: string) {
  return (await httpsCallable(functions.defaultRegion, 'getUser')({ userId })).data as FirebaseUser;
}

export function useAuthUser(userId: string | undefined) {
  const [user, setUser] = useState<FirebaseUser>();

  useEffect(() => {
    if (userId) {
      (async () => {
        setUser(await getUser(userId));
      })();
    } else {
      setUser(undefined);
    }
  }, [userId, setUser]);

  return user;
}

export async function listUsers() {
  return (await httpsCallable(functions.defaultRegion, 'listUsers')({})).data as FirebaseUser[];
}

export function useAuthUserList() {
  const user = useLoggedInUser();
  const [users, setUsers] = useState<FirebaseUser[]>();

  const sortByCreatedDate = useCallback((users: FirebaseUser[]) => {
    return users.sort((userA, userB) => {
      const creationTimeA = new Date(userA.metadata.creationTime ?? '').getTime();
      const creationTimeB = new Date(userB.metadata.creationTime ?? '').getTime();
      return creationTimeB - creationTimeA;
    });
  }, []);

  useEffect(() => {
    if (user) {
      (async () => {
        setUsers(sortByCreatedDate(await listUsers()));
      })();
    }
  }, [user, sortByCreatedDate]);

  return users;
}

export async function generateOfflineLicenseKeyString(
  userId: string,
  installation: Installation<FirewardGetOutput>,
  publicKey: string,
  { keyVersion }: { keyVersion: 1 | 2 | 3 | 4 | 5 } = { keyVersion: 5 }
) {
  const args = {
    ...installation,
    overrideUserId: userId,
    publicKey,
    ...(keyVersion > 1 && { keyVersion }),
  };
  const licenseKeyString = (
    await httpsCallable(functions.defaultRegion, 'validateInstallation')(args)
  ).data as string;
  return licenseKeyString;
}

/**
 * Use the `createPortalLink` function of the Firebase Stripe Payments extension to get a link to the customer's payments portal
 *
 * Can be used to modify an existing subscription
 *
 * @returns Stripe Customer Portal URL of the logged in user
 */
export async function stripeCustomerPortalUrl(
  existingSubscription?: [string, StripeSubscription<FirewardGetOutput>, ...unknown[]],
  modifyItem?: 'cancel' | { price?: string; quantity?: number }
) {
  const flow_data: Stripe.BillingPortal.SessionCreateParams.FlowData | undefined =
    existingSubscription
      ? {
          ...(modifyItem === 'cancel'
            ? {
                type: 'subscription_cancel',
                subscription_cancel: { subscription: existingSubscription[0] },
              }
            : modifyItem
            ? {
                type: 'subscription_update_confirm',
                subscription_update_confirm: {
                  subscription: existingSubscription[0],
                  // Assumes there is only one item in the subscription
                  items: [{ id: existingSubscription[1].items[0].id, ...modifyItem }],
                },
              }
            : {
                type: 'subscription_update',
                subscription_update: {
                  subscription: existingSubscription[0],
                },
              }),
          after_completion: {
            type: 'redirect',
            redirect: { return_url: window.location.href },
          },
        }
      : undefined;

  const createPortalLink = httpsCallable(
    functions.europeWest1,
    'ext-firestore-stripe-payments-createPortalLink'
  );
  const { data } = await createPortalLink({ returnUrl: window.location.href, flow_data });
  const url = (data as { url: string }).url;
  return url;
}

export async function importOrdersFromSquarespace() {
  return await httpsCallable(functions.europeWest1, 'squarespace-importOrders')({});
}

export async function importOrdersFromShopify() {
  return await httpsCallable(functions.europeWest1, 'shopify-importOrders')({});
}

export async function listShopifyWebhooks() {
  return (await httpsCallable(functions.europeWest1, 'shopify-listWebhooks')({})).data as Record<
    string,
    unknown
  >;
}

export async function setupShopifyWebhooks() {
  return (await httpsCallable(functions.europeWest1, 'shopify-setupWebhooks')({})).data;
}
